import Color from 'color';
import { defaultTheme } from 'evergreen-ui';
import { merge } from 'lodash/fp';

const white = new Color('#fff');
const neutral = new Color('#425A70');
const green = new Color('#23d7a9');
const blue = new Color('#1070ca');
const teal = new Color('#37BEF1');
const yellow = new Color('#F9CC06');
const pink = new Color('#F1375F');
const red = new Color('#ec4c47');
const orange = new Color('#d9822b');
const purple = new Color('#735dd0');

const hoverState = '&:not([disabled]):not([data-disabled]):hover';
const focusState = '&:not([disabled]):not([data-disabled]):focus';
const activeState =
  '&:not([disabled]):not([data-disabled]):active, &:not([disabled]):not([data-disabled])[aria-expanded="true"], &:not([disabled]):not([data-disabled])[data-active]';

const alphas = [0.04, 0.06, 0.09, 0.14, 0.3, 0.47, 0.7, 0.81];
const makeAlphas = (prefix, color) =>
  alphas.reduce((memo, a, i) => {
    memo[`${prefix}${i + 1}A`] = color.alpha(a).string();
    return memo;
  }, {});

// const defaultBorderColor = neutral.mix(white, 0.8);
// const defaultBackgroundColor = neutral.mix(white, 0.95);

const makeButtonStyles = (
  color,
  textColor = white,
  borderColor = color.mix(white, 0.1)
) => ({
  border: '1px solid transparent',
  borderColor: borderColor.string(),
  color: color.string(),

  '&[disabled]': {
    background: 'transparent',
    color: neutral.mix(white, 0.8).string(),
    borderColor: neutral.mix(white, 0.9).string(),
  },

  [hoverState]: {
    borderColor: borderColor.mix(color, 0.2).string(),
    backgroundColor: neutral.mix(white, 0.96).string(),
  },
  [focusState]: {
    zIndex: 2,
    boxShadow: `0 0 0 3px ${colors.blue.B4A}`,
  },
  [activeState]: {
    borderColor: borderColor.darken(0.2).string(),
    backgroundColor: neutral.mix(white, 0.96).string(),
    boxShadow: `0 0 0 3px ${colors.blue.B4A}`,
  },
  appearances: {
    minimal: {
      borderColor: 'transparent',

      [hoverState]: {
        backgroundColor: colors.neutral.N2A,
      },
      [focusState]: {
        zIndex: 2,
        boxShadow: `0 0 0 3px ${colors.blue.B4A}`,
      },
      [activeState]: {
        color: color.darken(0.08 * 2).string(),
        boxShadow: 'none',
        backgroundColor: color.alpha(0.04).string(),
      },
      '&[disabled]': {
        borderColor: 'transparent',
        color: neutral.mix(white, 0.8).string(),
      },
    },
    primary: {
      backgroundColor: color.string(),
      borderColor: color.string(),
      color: textColor.string(),

      [hoverState]: {
        backgroundColor: color.darken(0.03 * 3).string(),
        borderColor: color.darken(0.03 * 3).string(),
      },
      [focusState]: {
        backgroundColor: color.string(),
        borderColor: color.string(),
        boxShadow: `0 0 0 3px ${color.alpha(0.2).string()}`,
      },
      [activeState]: {
        backgroundColor: color.darken(0.04 * 3).string(),
        borderColor: color.darken(0.04 * 3).string(),
      },
    },
  },
});

// prettier-ignore
export const colors = {
  white: {
    color: white,
    base: white.hex(),
    ...makeAlphas('W', white),
  },
  neutral: {
    color:    neutral,
    N1:       neutral.mix(white, 1 - 0.034).hex(),
    lightest: neutral.mix(white, 1 - 0.034).hex(),
    N2:       neutral.mix(white, 1 - 0.055).hex(),
    N3:       neutral.mix(white, 1 - 0.093).hex(),
    N4:       neutral.mix(white, 1 - 0.142).hex(),
    light:    neutral.mix(white, 1 - 0.142).hex(),
    N5:       neutral.mix(white, 1 - 0.298).hex(),
    N6:       neutral.mix(white, 1 - 0.473).hex(),
    N7:       neutral.mix(white, 1 - 0.7).hex(),
    N8:       neutral.mix(white, 1 - 0.8120).hex(),
    N9:       neutral.hex(),
    base:     neutral.hex(),
    N10:      neutral.darken(0.08 * 2).hex(),
    dark:     neutral.darken(0.08 * 2).hex(),
    ...makeAlphas('N', neutral),
  },
  green: {
    color:    green,
    lightest: green.mix(white, 1 - 0.041).hex(),
    light:    green.mix(white, 1 - 0.204).hex(),
    base:     green.hex(),
    dark:     green.darken(0.08 * 5).hex(),
  },
  blue: {
    color:    blue,
    B1:       blue.mix(white, 1 - 0.034).hex(),
    lightest: blue.mix(white, 1 - 0.034).hex(),
    B2:       blue.mix(white, 1 - 0.055).hex(),
    B3:       blue.mix(white, 1 - 0.093).hex(),
    B4:       blue.mix(white, 1 - 0.142).hex(),
    light:    blue.mix(white, 1 - 0.142).hex(),
    B5:       blue.mix(white, 1 - 0.298).hex(),
    B6:       blue.mix(white, 1 - 0.473).hex(),
    B7:       blue.mix(white, 1 - 0.7).hex(),
    B8:       blue.mix(white, 1 - 0.8120).hex(),
    B9:       blue.hex(),
    base:     blue.hex(),
    B10:      blue.darken(0.08 * 2).hex(),
    dark:     blue.darken(0.08 * 2).hex(),
    ...makeAlphas('B', blue),
  },
  red: {
    color:    red,
    lightest: red.mix(white, 1 - 0.041).hex(),
    light:    red.mix(white, 1 - 0.204).hex(),
    base:     red.hex(),
    dark:     red.darken(0.08 * 5).hex(),
  },
  pink: {
    color:    pink,
    lightest: pink.mix(white, 1 - 0.041).hex(),
    light:    pink.mix(white, 1 - 0.204).hex(),
    base:     pink.hex(),
    dark:     pink.darken(0.08 * 5).hex(),
  },
  orange: {
    color:    orange,
    lightest: orange.mix(white, 1 - 0.041).hex(),
    light:    orange.mix(white, 1 - 0.204).hex(),
    base:     orange.hex(),
    dark:     orange.darken(0.08 * 5).hex(),
  },
  yellow: {
    color:    yellow,
    lightest: yellow.mix(white, 1 - 0.041).hex(),
    light:    yellow.mix(white, 1 - 0.204).hex(),
    base:     yellow.hex(),
    dark:     yellow.darken(0.08 * 5).hex(),
  },
  purple: {
    color:    purple,
    lightest: purple.mix(white, 1 - 0.041).hex(),
    light:    purple.mix(white, 1 - 0.204).hex(),
    base:     purple.hex(),
    dark:     purple.darken(0.08 * 5).hex(),
  },
  teal: {
    color:    teal,
    lightest: teal.mix(white, 1 - 0.041).hex(),
    light:    teal.mix(white, 1 - 0.204).hex(),
    base:     teal.hex(),
    dark:     teal.darken(0.08 * 5).hex(),
  },
};

const fontFamilies = {
  display: `"SF UI Display", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  ui: `"SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  mono: `"SF Mono", "Monaco", "Inconsolata", "Fira Mono", "Droid Sans Mono", "Source Code Pro", monospace`,
};

// Override evergreen theme values
export const evergreenTheme = merge(defaultTheme, {
  components: {
    Badge: {
      appearances: {
        solid: (theme, props) => {
          const color = colors?.[props.color]?.base || props.color;

          return {
            color: white.hex(),
            backgroundColor: color,
          };
        },
      },
    },
  },
});

export const theme = {
  breakpoints: ['32em', '48em', '64em'],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fontSizes: [12, 14, 16, 20, 24, 36, 48, 80, 96],
  fontWeights: ['normal', 100, 200, 300, 400, 500, 600, 700, 800, 900, 'bold'],
  lineHeights: {
    solid: 1,
    title: 1.25,
    copy: 1.5,
    double: 2,
  },
  letterSpacings: {
    normal: 'normal',
    tracked: '0.1em',
    tight: '-0.05em',
    mega: '0.25em',
  },
  fonts: fontFamilies,
  borders: {
    0: 0,
    1: '1px solid',
    2: '2px solid',
    3: '4px solid',
    4: '8px solid',
    5: '16px solid',
    6: '32px solid',
    inputs: `1px solid ${colors.neutral.N5A}`,
    default: `1px solid ${colors.neutral.N4A}`,
    dark: `1px solid ${colors.neutral.N6A}`,
    muted: `1px solid ${colors.neutral.N3A}`,
    dashed1: `3px dashed ${colors.neutral.N3A}`,
    dashed2: `1px dashed currentColor`,
    transparent: '1px solid transparent',
  },
  shadows: {
    textInput: `inset 0 0 0 1px ${colors.neutral.N4A}, inset 0 2px 0 ${colors.neutral.N1A}`,
    textInputFocus: `0 0 0 3px ${colors.blue.B4A}, inset 0 0 0 1px ${colors.blue.B6A}, inset 0 -1px 1px 0 ${colors.neutral.N5A}`,
    textInputError: `inset 0 0 0 1px ${colors.red.base}`,
    scrollableBox: `inset 0 -20px 20px -20px ${colors.neutral.N3A}`,
  },
  elevations: [
    `0 0 1px ${colors.neutral.N5A}`,
    `0 0 1px ${colors.neutral.N5A}, 0 2px 4px -2px ${colors.neutral.N6A}`,
    `0 0 1px ${colors.neutral.N5A}, 0 5px 8px -4px ${colors.neutral.N6A}`,
    `0 0 1px ${colors.neutral.N5A}, 0 8px 10px -4px ${colors.neutral.N6A}`,
    `0 0 1px ${colors.neutral.N5A}, 0 16px 24px -8px ${colors.neutral.N6A}`,
  ],
  background: {
    tint1: { backgroundColor: colors.neutral.N1 },
    tint2: { backgroundColor: colors.neutral.N2 },
    overlay: { backgroundColor: colors.neutral.N7A },
    orangeStripes: `repeating-linear-gradient(
      -45deg,
      ${colors.orange.base},
      ${colors.orange.base} 12px,
      transparent 10px,
      transparent 23px
    )`,

    selected: {
      backgroundColor: colors.blue.B1A,
      boxShadow: `inset 0 0 0 1px ${colors.blue.B5A}`,
    },
  },
  radii: [0, 2, 4, 6, 16, 9999, '100%'],
  sizes: [16, 32, 64, 128, 256, 512, 768, 1024, 1536],
  colors: {
    text: colors.neutral.base,
    textDark: colors.neutral.dark,
    textMuted: colors.neutral.N7,
    primary: colors.green,
    ...colors,
    success: colors.green.base,
    danger: colors.red.base,
    warning: colors.yellow.base,
    disabled: colors.neutral.N6,
    info: colors.blue.base,
    muted: colors.neutral.N7,
  },
  statusBanner: {
    intents: {
      none: {
        backgroundColor: colors.neutral.dark,
        color: colors.white.base,
        appearances: {
          muted: {
            backgroundColor: colors.neutral.light,
            color: colors.neutral.base,
          },
        },
      },
      danger: {
        backgroundColor: colors.red.base,
        color: colors.white.base,
      },
    },
  },
  buttons: (() => {
    const neutral = makeButtonStyles(
      colors.neutral.color,
      white,
      colors.neutral.color.mix(white, 0.7)
    );
    const blue = makeButtonStyles(colors.blue.color);
    const success = makeButtonStyles(colors.green.color);
    const warning = makeButtonStyles(colors.orange.color);
    const danger = makeButtonStyles(colors.red.color);

    return {
      intents: {
        none: neutral,
        success,
        danger,
        warning,
      },
      default: neutral,
      primary: success,
      secondary: blue,
    };
  })(),
};

theme.buttons.green = theme.buttons.primary;

export default theme;
