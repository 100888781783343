import { createAction } from 'redux-actions';
import { EventTypes } from 'redux-segment';

export const SET_CURRENT_ORGANISATION_ID = 'SET_CURRENT_ORGANISATION_ID';
export const setCurrentOrganisationId = createAction(
  SET_CURRENT_ORGANISATION_ID,
  (id) => id,
  (id) => ({
    analytics: {
      eventType: EventTypes.group,
      eventPayload: {
        groupId: id,
      },
    },
  })
);
